import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-link';
import { clsx } from 'clsx';

import './index.scss';

const CGBLink = ({ as, to, children, className, ...attributes }) => {
    let Component = as;
    // If external link switch to <a></a>
    if (to.indexOf('http') === 0) {
        Component = 'a';
    } else if (!to.endsWith('/')) {
        to = `${to}/`;
    }

    return (
        <Component
            className={clsx('link', className)}
            to={typeof Component !== 'string' ? to : undefined}
            href={typeof Component === 'string' ? to : undefined}
            {...attributes}
        >
            {children}
        </Component>
    );
};

CGBLink.defaultProps = {
    className: '',
    to: '',
    as: Link,
};

CGBLink.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    to: PropTypes.string,
    as: PropTypes.oneOf([Link, 'a']),
};

export { CGBLink };
