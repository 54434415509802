/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';

import { DefaultLayout } from '../layouts/default';

import { SingleImage } from '../components/atoms/single-image';
import { PageTitle } from '../components/atoms/page-title';
import { Testimonial } from '../components/atoms/testimonial';
import { TwoCol } from '../components/atoms/two-col';
import { RteContent } from '../components/atoms/rte-content';
import { SEO } from '../components/atoms/seo';

import { StudioIntro } from '../components/molecules/studio-intro';
import { HonorsContainer } from '../components/molecules/honors-container';
import { ContactDetails } from '../components/molecules/contact-details';
import { CookieBanner } from '../components/molecules/cookie-banner';

const StudioPage = ({ data }) => {
    const {
        id,
        title,
        expertise,
        introductoryContent,
        fullWidthImage,
        selectedClients,
        awards,
        features,
        talks,
        testimonials,
        fullWidthImage2,
        team,
        studioContent,
    } = data.datoCmsStudio;

    const {
        footerLocation,
        footerAddress,
        footerEmailAddress,
        footerSocialMediaHandle,
        footerSocialLink,
        footerPhoneNumber,
        footerOpeningTimes,
    } = data.datoCmsGeneralSetting;

    const introContent = {
        ...introductoryContent,
    };

    const honorsContainerContent = {
        selectedClients,
        awards,
        features,
        talks,
    };

    const testimonialId = 'studio-testimonials';

    let teamList = [];
    if (team.length > 0) {
        team.map((member) =>
            teamList.push({
                id: member.id,
                item: `${member.name} - ${member.role}`,
            })
        );
    }

    const contactContent = {
        team: teamList,
        location: footerLocation,
        address: footerAddress,
        email: footerEmailAddress,
        socialHandle: footerSocialMediaHandle,
        socialLink: footerSocialLink,
        phoneNumber: footerPhoneNumber,
        openingTimes: footerOpeningTimes,
    };

    return (
        <>
            <PageTitle className="page-title--slide">{title}</PageTitle>

            <StudioIntro introContent={introContent} expertise={expertise} />

            {Object.keys(fullWidthImage.image).length > 0 ? (
                <SingleImage
                    title={fullWidthImage.title}
                    image={fullWidthImage.image}
                />
            ) : null}

            <HonorsContainer content={honorsContainerContent} />

            {testimonials && testimonials.length === 3 ? (
                <>
                    <PageTitle id={testimonialId}>Testimonials</PageTitle>
                    <TwoCol
                        aria-labelledby={testimonialId}
                        colClassName="two-col__col--space-text"
                        firstColItems={[
                            <Testimonial
                                key={testimonials[0].id}
                                reviewer={testimonials[0].reviewer}
                                review={testimonials[0].review}
                            />,
                            <Testimonial
                                key={testimonials[1].id}
                                reviewer={testimonials[1].reviewer}
                                review={testimonials[1].review}
                            />,
                        ]}
                        secondColItems={[
                            <Testimonial
                                key={testimonials[2].id}
                                reviewer={testimonials[2].reviewer}
                                review={testimonials[2].review}
                            />,
                        ]}
                    />
                </>
            ) : null}

            {Object.keys(fullWidthImage2.image).length > 0 ? (
                <SingleImage
                    title={fullWidthImage2.title}
                    image={fullWidthImage2.image}
                />
            ) : null}

            <TwoCol
                reverse
                firstColItems={[
                    <RteContent
                        key={`studioContent${id}`}
                        content={studioContent}
                    />,
                ]}
                secondColItems={[
                    <ContactDetails
                        key={`contactDetails${id}`}
                        content={contactContent}
                    />,
                ]}
            />
        </>
    );
};

export const query = graphql`
    query {
        datoCmsStudio {
            id
            title
            expertise {
                text
                mainExpertise
            }
            introductoryContent {
                blocks
                links
                value
            }
            fullWidthImage {
                title
                image {
                    alt
                    gatsbyImageData(width: 1920)
                }
            }
            selectedClients {
                id
                item
            }
            awards {
                id
                title: date
                description: item
            }
            features {
                id
                title: date
                description: item
            }
            talks {
                id
                title: date
                description: item
            }
            testimonials {
                id
                reviewer
                review {
                    blocks
                    links
                    value
                }
            }
            fullWidthImage2 {
                title
                image {
                    alt
                    gatsbyImageData(width: 1920)
                }
            }
            team {
                id
                name
                role
            }
            studioContent {
                blocks
                links
                value
            }
        }
        datoCmsGeneralSetting {
            footerLocation
            footerAddress
            footerEmailAddress
            footerSocialMediaHandle
            footerSocialLink
            footerPhoneNumber
            footerOpeningTimes
        }
    }
`;

const WrappedStudio = ({ data, location }) => (
    <DefaultLayout hideFooterContent={true} footerClassName="footer--w-studio">
        <StudioPage data={data} />
        <CookieBanner hostname={location.hostname} privacyUrl="/privacy" />
    </DefaultLayout>
);

export default WrappedStudio;

export const Head = ({ data, location }) => {
    const { title } = data.datoCmsStudio;

    return <SEO title={title} pathname={location.pathname} />;
};
