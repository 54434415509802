import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import './index.scss';

import { Tile } from '../../atoms/tile';
import { datoGatsbyImageProp } from '../../../types';

const TileContainer = ({ tiles, className }) => (
    <section className={clsx('tile-container', className)}>
        {tiles.map((tile) => (
            <Tile key={tile.id} {...tile} />
        ))}
    </section>
);

TileContainer.defaultProps = {
    className: '',
    tiles: {
        id: '',
    },
};

TileContainer.propTypes = {
    className: PropTypes.string,
    tiles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            tileTitle: PropTypes.string,
            service: PropTypes.arrayOf(
                PropTypes.shape({
                    serviceName: PropTypes.string,
                })
            ),
            year: PropTypes.string,
            cover: datoGatsbyImageProp,
        })
    ).isRequired,
};

export { TileContainer };
