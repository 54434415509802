/* eslint-disable react/prop-types */
import React from 'react';

import { DefaultLayout } from '../layouts/default';

import { PageTitle } from '../components/atoms/page-title';
import { SEO } from '../components/atoms/seo';

import { CookieBanner } from '../components/molecules/cookie-banner';

const pageTitle = 'Page not found';

const FourOhFour = () => (
    <>
        <PageTitle>{pageTitle}</PageTitle>
        <p className="fourohfour-text">
            That page doesn&apos;t exist. Please try again.
        </p>
    </>
);

const WrappedFourOhFour = ({ location }) => (
    <DefaultLayout>
        <FourOhFour />
        <CookieBanner hostname={location.hostname} privacyUrl="/privacy" />
    </DefaultLayout>
);

export default WrappedFourOhFour;

export const Head = ({ location }) => (
    <SEO title={pageTitle} pathname={location.pathname} />
);
