import { useState, useEffect } from 'react';

export const useDynamicGraphQLCall = ({ filter, pagination, query }) => {
    const token = process.env.GATSBY_DATO_API_KEY;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);

                const res = await fetch('https://graphql.datocms.com/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        query,
                    }),
                });

                const { data: resData } = await res.json();
                setLoading(false);

                if (!res.ok) {
                    return setError(
                        `Failed to fetch data, ${
                            resData[0]?.attributes?.code || ''
                        }`
                    );
                }

                setData(resData);
                setError(null);
            } catch (e) {
                setError('Failed to fetch data');
            }
        }

        fetchData();
    }, [filter, pagination, query, token]);

    return { data, error, loading };
};
