/* eslint-disable react/prop-types */
import React from 'react';
import { useStaticQuery, graphql, Script } from 'gatsby';

import { DefaultLayout } from '../layouts/default';

import { Vimeo } from '../components/atoms/vimeo';
import { SingleImage } from '../components/atoms/single-image';
import { SEO } from '../components/atoms/seo';

import { Carousel } from '../components/molecules/carousel';
import { CookieBanner } from '../components/molecules/cookie-banner';

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query {
            datoCmsHomepage {
                carousel {
                    images {
                        alt
                        gatsbyImageData(width: 560, height: 755)
                    }
                    title
                    originalId
                }
                sectionOne {
                    __typename
                    ... on DatoCmsFullWidthImage {
                        title
                        image {
                            alt
                            gatsbyImageData(width: 1920)
                        }
                    }
                    ... on DatoCmsVimeo {
                        internalTitle
                        vimeoVideo {
                            url
                        }
                        vimeoTitle
                    }
                }
                sectionOneProjectLink {
                    slug
                }
                sectionTwo {
                    __typename
                    ... on DatoCmsFullWidthImage {
                        title
                        image {
                            alt
                            gatsbyImageData(width: 1920)
                        }
                    }
                    ... on DatoCmsVimeo {
                        internalTitle
                        vimeoVideo {
                            url
                        }
                        vimeoTitle
                    }
                }
                sectionTwoProjectLink {
                    slug
                }
                sectionThree {
                    __typename
                    ... on DatoCmsFullWidthImage {
                        title
                        image {
                            alt
                            gatsbyImageData(width: 1920)
                        }
                    }
                    ... on DatoCmsVimeo {
                        internalTitle
                        vimeoVideo {
                            url
                        }
                        vimeoTitle
                    }
                }
                sectionThreeProjectLink {
                    slug
                }
            }
        }
    `);

    const {
        carousel,
        sectionOne,
        sectionOneProjectLink,
        sectionTwo,
        sectionTwoProjectLink,
        sectionThree,
        sectionThreeProjectLink,
    } = data.datoCmsHomepage;

    const sections = [
        {
            media: sectionOne,
            url: sectionOneProjectLink,
        },
        {
            media: sectionTwo,
            url: sectionTwoProjectLink,
        },
        {
            media: sectionThree,
            url: sectionThreeProjectLink,
        },
    ];

    return (
        <>
            <Carousel
                id={carousel.originalId}
                title={carousel.title}
                images={carousel.images}
                loading="eager"
            />
            {sections.map((section, index) => {
                if (section.media.__typename === 'DatoCmsVimeo') {
                    return (
                        <Vimeo
                            key={`homePageVimeo${index}`}
                            videoTitle={section.media.vimeoTitle}
                            video={section.media.vimeoVideo.url}
                            controls={false}
                            loop={true}
                            to={
                                section.url && section.url.slug
                                    ? `work/${section.url.slug}`
                                    : null
                            }
                        />
                    );
                } else {
                    return (
                        <SingleImage
                            key={`homePageImage${index}`}
                            title={section.media.title}
                            image={section.media.image}
                            to={
                                section.url && section.url.slug
                                    ? `work/${section.url.slug}`
                                    : null
                            }
                        />
                    );
                }
            })}
        </>
    );
};

const WrappedHome = ({ location }) => (
    <DefaultLayout>
        <IndexPage />
        <CookieBanner hostname={location.hostname} privacyUrl="/privacy" />
    </DefaultLayout>
);

export default WrappedHome;

export const Head = () => (
    <>
        <SEO />
        <Script
            type="module"
            src="https://cdn.jsdelivr.net/npm/playerx/+esm"
            strategy="idle"
        ></Script>
    </>
);
