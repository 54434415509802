import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

import { Vimeo } from '../../atoms/vimeo';
import { Youtube } from '../../atoms/youtube';
import clsx from 'clsx';

const Video = ({ id, ...props }) => {
    const { caption, video } = props;
    const { __typename: type, ...rest } = video;

    const getVideoType = (type) => {
        if (type === 'DatoCmsYoutube') {
            return (
                <Youtube
                    video={rest.youtubeVideo.url}
                    loop={true}
                    controls={true}
                />
            );
        } else if (type === 'DatoCmsVimeo') {
            return (
                <Vimeo
                    video={rest.vimeoVideo.url}
                    loop={true}
                    controls={true}
                />
            );
        } else {
            return;
        }
    };

    return (
        <figure
            id={id}
            className={clsx('video', !caption ? 'video--space' : null)}
        >
            {getVideoType(type)}
            {caption ? (
                <figcaption className="video__caption">{caption}</figcaption>
            ) : null}
        </figure>
    );
};

Video.defaultProps = {
    id: '',
    caption: '',
    fullScreen: false,
};

Video.propTypes = {
    id: PropTypes.string,
    caption: PropTypes.string,
    video: PropTypes.shape({
        __typename: PropTypes.string.isRequired,
        id: PropTypes.string,
        youtubeVideo: PropTypes.shape({
            url: PropTypes.string,
        }),
        vimeoVideo: PropTypes.shape({
            url: PropTypes.string,
        }),
    }).isRequired,
    fullScreen: PropTypes.bool,
};

export { Video };
