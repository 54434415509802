import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import './index.scss';

import { descriptionListItemProps } from '../../../types';

const DescriptionList = ({
    title,
    items,
    className,
    listClassName,
    blockClassName,
}) => (
    <div className={clsx('description-list', className)}>
        {title ? <p>{title}</p> : null}
        <dl className={clsx(listClassName)}>
            {items.map((item) => {
                let descriptions = item.description;
                if (
                    typeof item.description === 'string' ||
                    item.description instanceof String
                ) {
                    descriptions = descriptions.split(/\r?\n/);
                }
                return (
                    <div
                        className={clsx(
                            'description-list__block',
                            blockClassName
                        )}
                        key={item.id}
                    >
                        <dt>{item.title}</dt>
                        {descriptions.map((description, index) => (
                            <dd key={`${item.id}description${index}`}>
                                {description}
                            </dd>
                        ))}
                    </div>
                );
            })}
        </dl>
    </div>
);

DescriptionList.defaultProps = {
    className: '',
    listClassName: '',
    blockClassName: '',
    title: '',
};

DescriptionList.propTypes = {
    className: PropTypes.string,
    listClassName: PropTypes.string,
    blockClassName: PropTypes.string,
    title: PropTypes.string,
    items: descriptionListItemProps.isRequired,
};

export { DescriptionList };
