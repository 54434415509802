/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import { DefaultLayout } from '../layouts/default';

import { ServiceFilter } from '../components/atoms/service-filter';
import { PageTitle } from '../components/atoms/page-title';
import { CGBLink } from '../components/atoms/cgb-link';
import { SEO } from '../components/atoms/seo';

import { TileContainer } from '../components/molecules/tile-container';
import { PagesList } from '../components/molecules/pages-list';
import { CookieBanner } from '../components/molecules/cookie-banner';

import { useFilters } from '../hooks/filters';

const WorkPage = ({ data }) => {
    const { id } = data.datoCmsAllWork;
    const { nodes: workPages } = data.allDatoCmsProject;

    const services = [];
    workPages.map((page) => {
        page.service.map((service) => {
            const found = services.some((el) => el.id === service.id);
            if (!found) services.push(service);
            return services;
        });
    });

    // Form query to filter services depending on props received
    const filterServiceQuery = ({ queryName, nestedQueryName, filter }) => {
        if (filter)
            filter = `(filter: {service: {anyIn: ${filter}}}, orderBy: tileTitle_ASC)`;
        return `
        query {
            ${queryName}${nestedQueryName && `{${nestedQueryName}`}${filter} {
                id
                tileTitle
                service {
                    serviceName
                }
                year
                cover {
                    responsiveImage(imgixParams: {fit: crop, ar: "1"}) {
                        src
                        srcSet
                        sizes
                        base64
                        width
                        height
                        alt
                    }
                }
                slug
            }
        }
        ${nestedQueryName && `}`}
    `;
    };

    // Set initial query to display featured tiles
    const [queryName, setQueryName] = useState('allWork');
    const [nestedQueryName, setNestedQueryName] = useState('');
    const { error, setFilter, items } = useFilters({
        queryName: queryName,
        nestedQueryName: nestedQueryName,
        query: filterServiceQuery,
        data: data?.allDatoCmsAllWork,
    });

    // Set tiles depending on object structure
    const [tiles, setTiles] = useState();
    useEffect(() => {
        if (items[0].featuredTiles) {
            setTiles(items[0].featuredTiles);
        } else {
            setTiles(items);
        }
    }, [items]);

    const allWorkPageProps = ['id', 'tileTitle', 'slug'];
    const allWorkPages = workPages
        .map(function (obj) {
            return allWorkPageProps.reduce(function (
                newObj,
                name,
                index,
                array
            ) {
                newObj[name] = obj[name];
                if (index === array.length - 1) {
                    // Format for List component
                    return {
                        ...newObj,
                        id: newObj.id,
                        item: (
                            <CGBLink to={newObj.slug}>
                                {newObj.tileTitle}
                            </CGBLink>
                        ),
                    };
                } else {
                    return newObj;
                }
            },
            {});
        })
        .sort((a, b) => a.tileTitle.localeCompare(b.tileTitle));

    return (
        <>
            <PageTitle className="screen-reader-text">Work</PageTitle>

            <ServiceFilter
                setFilter={setFilter}
                setQueryName={setQueryName}
                setNestedQueryName={setNestedQueryName}
                options={services}
                title="Services"
                buttonId={`${id}Button`}
                contentId={`${id}Content`}
            />

            {error ? <p>{error}</p> : null}
            {tiles && tiles.length > 0 ? (
                <TileContainer tiles={tiles} />
            ) : (
                <p>Sorry no results found</p>
            )}

            <PagesList items={allWorkPages} />
        </>
    );
};

export const data = graphql`
    query {
        allDatoCmsAllWork {
            nodes {
                featuredTiles {
                    id: originalId
                    tileTitle
                    service {
                        serviceName
                    }
                    year
                    cover {
                        alt
                        gatsbyImageData(
                            width: 730
                            imgixParams: {
                                ar: "1:1"
                                fit: "crop"
                                crop: "focalpoint"
                            }
                        )
                    }
                    slug
                }
            }
        }
        allDatoCmsProject {
            nodes {
                id: originalId
                tileTitle
                slug
                service {
                    id: originalId
                    name: serviceName
                }
            }
        }
        datoCmsAllWork {
            id
            title
        }
    }
`;

const WrappedWork = ({ data, location }) => (
    <DefaultLayout>
        <WorkPage data={data} />
        <CookieBanner hostname={location.hostname} privacyUrl="/privacy" />
    </DefaultLayout>
);

export default WrappedWork;

export const Head = ({ data, location }) => {
    const { title } = data.datoCmsAllWork;

    return <SEO title={title} pathname={location.pathname} />;
};
