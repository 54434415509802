import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './index.scss';

import { RteContent } from '../../atoms/rte-content';

import { structuredTextContent, datoGatsbyImageProp } from '../../../types';

const ProjectIntro = ({ ...props }) => {
    const { content, logo, logoPosition, show } = props;

    const logoData = getImage(logo);
    const alt = logo?.alt || '';

    return (
        <section className="project-intro">
            <RteContent content={content} />
            {show ? (
                <div
                    className={`project-intro__logo project-intro__logo--${logoPosition}`}
                >
                    <GatsbyImage
                        image={logoData}
                        objectFit="contain"
                        quality={100}
                        alt={alt}
                    />
                </div>
            ) : null}
        </section>
    );
};

ProjectIntro.defaultProps = {
    className: '',
    logo: {},
    logoPosition: '',
    show: false,
};

ProjectIntro.propTypes = {
    content: structuredTextContent.isRequired,
    logo: datoGatsbyImageProp,
    logoPosition: PropTypes.string,
    show: PropTypes.bool,
};

export { ProjectIntro };
