import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import './index.scss';

import { listItemProps } from '../../../types';

const List = ({ title, items, className }) => (
    <div className={clsx('list', className)}>
        {title ? <p>{title}</p> : null}
        <ul>
            {items.map((item) => (
                <li key={item.id}>{item.item}</li>
            ))}
        </ul>
    </div>
);

List.defaultProps = {
    className: '',
    title: '',
};

List.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    items: listItemProps.isRequired,
};

export { List };
