import React, { useEffect, useRef, useState } from 'react';

import './index.scss';

const ContactForm = () => {
    const formRef = useRef();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitMessage, setSubmitMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validate = (formData) => {
        let formErrors = {};

        if (!formData.name) {
            formErrors.name = 'Name is required';
        }

        if (!formData.email) {
            formErrors.email = 'Email is required';
        }

        if (!formData.message) {
            formErrors.message = 'Message is required';
        }

        return formErrors;
    };

    const handleSubmit = (e) => {
        setErrors(validate(formData));
        setIsSubmitted(true);
        e.preventDefault();
    };

    const encode = (data) =>
        Object.keys(data)
            .map(
                (key) =>
                    encodeURIComponent(key) +
                    '=' +
                    encodeURIComponent(data[key])
            )
            .join('&');

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitted) {
            fetch('/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: encode({
                    'form-name': formRef.current.getAttribute('name'),
                    ...formData,
                }),
            })
                .then(() =>
                    setSubmitMessage(
                        'Thank you for your message, I will be in touch shortly.'
                    )
                )
                .then(() => setIsSubmitted(false))
                .then(() => setFormData({ name: '', email: '', message: '' }))
                .catch((error) => alert(error));
        }
    }, [errors, isSubmitted]);

    return (
        <form
            ref={formRef}
            className="contact-form"
            onSubmit={handleSubmit}
            name="contact"
            data-netlify="true"
            // eslint-disable-next-line react/no-unknown-property
            netlify-honeypot="bot-field"
        >
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
                <label>
                    Don&apos;t fill this out:{' '}
                    <input name="bot-field" onChange={handleSubmit} />
                </label>
            </p>
            <div className="contact-form__field-group">
                <label className="contact-form__label" htmlFor="formName">
                    Your name:
                </label>
                <input
                    className="contact-form__input"
                    id="formName"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                />
                {errors.name && (
                    <p className="contact-form__error">{errors.name}</p>
                )}
            </div>
            <div className="contact-form__field-group">
                <label className="contact-form__label" htmlFor="formEmail">
                    Your email:
                </label>
                <input
                    className="contact-form__input"
                    id="formEmail"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                />
                {errors.email && (
                    <p className="contact-form__error">{errors.email}</p>
                )}
            </div>
            <div className="contact-form__field-group">
                <label className="contact-form__label" htmlFor="formMessage">
                    Message:
                </label>
                <textarea
                    className="contact-form__input"
                    id="formMessage"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows={5}
                />
                {errors.message && (
                    <p className="contact-form__error">{errors.message}</p>
                )}
            </div>
            <button className="contact-form__button" type="submit">
                Send
            </button>
            {submitMessage && (
                <p className="contact-form__success">{submitMessage}</p>
            )}
        </form>
    );
};

export { ContactForm };
