import React from 'react';
import PropTypes from 'prop-types';
import { useSiteMetadata } from '../../../hooks/site-meta/use-site-metadata';

const SEO = ({ title, description, pathname, children }) => {
    const {
        title: defaultTitle,
        description: defaultDescription,
        image,
        siteUrl,
    } = useSiteMetadata();

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}/${image}`,
        url: `${siteUrl}${pathname || ``}`,
    };

    return (
        <>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:image" content={seo.image} />
            <meta property="og:url" content={seo.url} />
            <meta name="twitter:card" content="summary_large_image" />
            {children}
        </>
    );
};

SEO.defaultProps = {
    title: '',
    description: '',
    pathname: '',
    children: <></>,
};

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    pathname: PropTypes.string,
    children: PropTypes.node,
};

export { SEO };
