import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { NavLink } from '../../atoms/nav-link';

import './index.scss';

const PrimaryNav = forwardRef(({ links, ...attributes }, ref) => (
    <nav ref={ref} className="primary-nav" {...attributes}>
        <ul className="primary-nav__list">
            {links.map((link) => (
                <li key={link.title.replace(/[^A-Z0-9]/gi, '')}>
                    <NavLink to={link.link}>{link.title}</NavLink>
                </li>
            ))}
        </ul>
    </nav>
));
PrimaryNav.displayName = 'PrimaryNav';

PrimaryNav.defaultProps = {};

PrimaryNav.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export { PrimaryNav };
