import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import '@fontsource/alexandria';
import '@fontsource/alexandria/300.css';
import '@fontsource/ibm-plex-mono/300.css';
import '@fontsource/unbounded/300.css';

import { MasterLayout } from '../master';

import { Footer } from '../../components/molecules/footer';
import { Header } from '../../components/organisms/header';

import { useCookie } from '../../hooks/cookie';

const DefaultLayout = ({
    children,
    className,
    footerClassName,
    hideFooterContent,
}) => {
    const data = useStaticQuery(graphql`
        query SiteSettingsQuery {
            datoCmsGeneralSetting {
                primaryNavigation {
                    title
                    link
                }
                footerLocation
                footerAddress
                footerEmailAddress
                footerSocialMediaHandle
                footerSocialLink
                footerPhoneNumber
                footerOpeningTimes
            }
        }
    `);

    const headerContent = data.datoCmsGeneralSetting.primaryNavigation;
    const {
        footerLocation,
        footerAddress,
        footerEmailAddress,
        footerSocialMediaHandle,
        footerSocialLink,
        footerPhoneNumber,
        footerOpeningTimes,
    } = data.datoCmsGeneralSetting;

    const gaTag = process.env.GATSBY_GA_TAG;

    useEffect(() => {
        if (useCookie('cookiesAccepted')) {
            window[`ga-disable-${gaTag}`] = false;
        } else {
            window[`ga-disable-${gaTag}`] = true;
        }
    }, []);

    return (
        <MasterLayout>
            <Header
                siteTitle="The Contemporary Graphics Bureau"
                headerContent={headerContent}
            />
            <main id="main" className={className}>
                {children}
            </main>
            <Footer
                className={footerClassName}
                hideContent={hideFooterContent}
                location={footerLocation}
                address={footerAddress}
                email={footerEmailAddress}
                socialHandle={footerSocialMediaHandle}
                socialLink={footerSocialLink}
                phoneNumber={footerPhoneNumber}
                openingTimes={footerOpeningTimes}
                privacyUrl="/privacy"
            />
        </MasterLayout>
    );
};

DefaultLayout.defaultProps = {
    className: '',
    hideFooterContent: false,
    footerClassName: '',
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    hideFooterContent: PropTypes.bool,
    footerClassName: PropTypes.string,
};

export { DefaultLayout };
