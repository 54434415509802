const getGatsbyImageFormat = (imageItem, layout, sizesArg) => {
    const {
        responsiveImage: {
            src,
            srcSet,
            sizes,
            base64: fallback,
            bgColor: backgroundColor,
            alt,
            width,
            height,
        },
    } = imageItem;

    return {
        alt,
        gatsbyImageData: {
            images: {
                sources: [],
                fallback: {
                    src,
                    srcSet,
                    sizes: sizesArg || sizes,
                },
            },
            layout,
            placeholder: {
                fallback,
            },
            backgroundColor,
            width,
            height,
        },
    };
};

export const mapToGatsbyFormat = (item) => {
    let obj = {
        ...item,
    };

    if (item.cover) {
        obj = {
            ...obj,
            cover: getGatsbyImageFormat(item.cover, 'constrained'),
        };
    }

    return obj;
};
