import React from 'react';
import PropTypes from 'prop-types';

import { Logo } from '../../atoms/logo';
import { PrimaryNav } from '../../molecules/primary-nav';

import './index.scss';

const Header = ({ headerContent, siteTitle }) => (
    <header className="header">
        <Logo
            title={siteTitle}
            className="header__logo logo--header"
            svgClassName="logo__svg--light"
        />
        <PrimaryNav className="header__nav" links={headerContent} />
    </header>
);

Header.defaultProps = {};

Header.propTypes = {
    siteTitle: PropTypes.string.isRequired,
    headerContent: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export { Header };
