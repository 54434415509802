import PropTypes from 'prop-types';

export const datoGatsbyImageProp = PropTypes.shape({
    url: PropTypes.string,
    gatsbyImageData: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        placeholder: PropTypes.shape({
            fallback: PropTypes.string,
        }),
        images: PropTypes.shape({
            fallback: PropTypes.shape({
                src: PropTypes.string,
                srcSet: PropTypes.string,
                sizes: PropTypes.string,
            }),
            sources: PropTypes.arrayOf(
                PropTypes.shape({
                    srcSet: PropTypes.string,
                    sizes: PropTypes.string,
                    type: PropTypes.string,
                })
            ),
        }),
        layout: PropTypes.string,
    }),
    alt: PropTypes.string,
});

export const structuredTextContent = PropTypes.shape({
    value: PropTypes.shape({
        document: PropTypes.shape({
            children: PropTypes.arrayOf(
                PropTypes.shape({
                    type: PropTypes.string.isRequired,
                    value: PropTypes.string,
                    url: PropTypes.string,
                    marks: PropTypes.arrayOf(PropTypes.string),
                    children: PropTypes.arrayOf(
                        PropTypes.shape({
                            type: PropTypes.string,
                            value: PropTypes.string,
                        })
                    ),
                })
            ).isRequired,
            type: PropTypes.string.isRequired,
        }),
    }),
    schema: PropTypes.string,
});

export const expertiseProps = PropTypes.arrayOf(
    PropTypes.shape({
        mainExpertise: PropTypes.bool,
        text: PropTypes.string,
    })
);

export const listItemProps = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.string,
        item: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    })
);

export const descriptionListItemProps = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
    })
);

export const testimonialProps = {
    reviewer: PropTypes.string.isRequired,
    review: structuredTextContent.isRequired,
};

export const backgroundColourProps = PropTypes.shape({
    hex: PropTypes.string,
});
