/* eslint-disable react/prop-types */
import React from 'react';

import { DefaultLayout } from '../layouts/default';

import { PageTitle } from '../components/atoms/page-title';
import { SEO } from '../components/atoms/seo';

import { ContactForm } from '../components/molecules/contact-form';
import { CookieBanner } from '../components/molecules/cookie-banner';

const pageTitle = 'Contact';
const ContactPage = () => (
    <>
        <PageTitle>{pageTitle}</PageTitle>
        <ContactForm />
    </>
);

const WrappedContact = ({ location }) => (
    <DefaultLayout>
        <ContactPage />
        <CookieBanner hostname={location.hostname} privacyUrl="/privacy" />
    </DefaultLayout>
);

export default WrappedContact;

export const Head = ({ location }) => (
    <SEO title={pageTitle} pathname={location.pathname} />
);
