import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './index.scss';

import { datoGatsbyImageProp, backgroundColourProps } from '../../../types';

const ImageGallery = ({ ...props }) => {
    const {
        backgroundColour,
        caption,
        className,
        images,
        equalWidths,
        halfWidth,
        alignment,
        shadow,
    } = props;
    return (
        <figure
            className={clsx(
                'image-gallery',
                !caption ? 'image-gallery--space' : null,
                className
            )}
        >
            <div
                className="image-gallery__background"
                style={{
                    backgroundColor:
                        backgroundColour && backgroundColour.hex
                            ? backgroundColour.hex
                            : '#FFF',
                }}
            >
                <div
                    className={clsx(
                        'image-gallery__grid',
                        halfWidth ? 'image-gallery__grid--shrink' : null
                    )}
                >
                    {images.map((image, index) => {
                        const imageData = getImage(image);
                        const alt = image.alt || '';
                        return (
                            <GatsbyImage
                                key={`${image.id}${index}`}
                                className={clsx(
                                    'image-gallery__image',
                                    equalWidths
                                        ? 'image-gallery__image--equal'
                                        : null,
                                    shadow
                                        ? 'image-gallery__image--shadow'
                                        : null
                                )}
                                loading="lazy"
                                image={imageData}
                                objectFit={equalWidths ? 'cover' : 'contain'}
                                objectPosition={alignment}
                                quality={100}
                                alt={alt}
                            />
                        );
                    })}
                </div>
            </div>
            {caption ? (
                <figcaption className="image-gallery__caption">
                    {caption}
                </figcaption>
            ) : null}
        </figure>
    );
};

ImageGallery.defaultProps = {
    className: '',
    backgroundColour: {
        hex: '#FFFFFF',
    },
    alignment: '50% 50%',
    caption: '',
    equalWidths: true,
    halfWidth: false,
    shadow: false,
};

ImageGallery.propTypes = {
    backgroundColour: backgroundColourProps,
    alignment: PropTypes.string,
    caption: PropTypes.string,
    className: PropTypes.string,
    images: PropTypes.arrayOf(datoGatsbyImageProp).isRequired,
    equalWidths: PropTypes.bool,
    halfWidth: PropTypes.bool,
    shadow: PropTypes.bool,
};

export { ImageGallery };
