import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './index.scss';

import { datoGatsbyImageProp, backgroundColourProps } from '../../../types';

const ImageGrid = ({ ...props }) => {
    const { id, backgroundColour, caption, columns, imagesFit, shadow } = props;

    return (
        <figure
            className={clsx(
                'image-grid',
                !caption ? 'image-grid--space' : null
            )}
        >
            <div
                className="image-grid__background"
                style={{
                    backgroundColor:
                        backgroundColour && backgroundColour.hex
                            ? backgroundColour.hex
                            : '#FFF',
                }}
            >
                <div
                    className={`image-grid__wrapper image-grid__wrapper--${columns.length}`}
                >
                    {columns.map((column, index) => (
                        <div
                            className="image-grid__column"
                            key={`${id}${index}`}
                        >
                            {column.images.map((image, index) => {
                                const imageData = getImage(image);
                                const alt = image.alt || '';
                                return (
                                    <GatsbyImage
                                        className={clsx(
                                            'image-grid__image',
                                            imagesFit === 'cover'
                                                ? 'image-grid__image--cover'
                                                : null,
                                            shadow
                                                ? 'image-grid__image--shadow'
                                                : null
                                        )}
                                        key={`${image.id}${index}`}
                                        objectFit={imagesFit}
                                        loading="lazy"
                                        image={imageData}
                                        quality={100}
                                        alt={alt}
                                    />
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
            {caption ? (
                <figcaption className="image-grid__caption">
                    {caption}
                </figcaption>
            ) : null}
        </figure>
    );
};

ImageGrid.defaultProps = {
    className: '',
    backgroundColour: {
        hex: '#FFFFFF',
    },
    caption: '',
    imagesFit: 'contain',
    shadow: false,
};

ImageGrid.propTypes = {
    id: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            images: PropTypes.arrayOf(datoGatsbyImageProp).isRequired,
        })
    ).isRequired,
    caption: PropTypes.string,
    className: PropTypes.string,
    backgroundColour: backgroundColourProps,
    imagesFit: PropTypes.oneOf(['contain', 'cover']),
    shadow: PropTypes.bool,
};

export { ImageGrid };
