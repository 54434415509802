/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import './index.scss';

import { expertiseProps } from '../../../types';

const Expertise = ({ items, className }) => {
    const mainExpertiseGrouped = items.reduce(function (memo, value) {
        const { mainExpertise, text } = value;
        if (!memo[value['mainExpertise']]) {
            memo[value['mainExpertise']] = [];
        }
        memo[value['mainExpertise']].push(text);
        return memo;
    }, {});

    return (
        <div className={clsx('expertise', className)}>
            {mainExpertiseGrouped?.true?.length > 0 ? (
                <div className="expertise__col">
                    <p>Main Expertise:</p>
                    <ul className="expertise__list">
                        {mainExpertiseGrouped.true.sort().map((item) => (
                            <li key={item}>{item}</li>
                        ))}
                    </ul>
                </div>
            ) : null}
            {mainExpertiseGrouped?.false?.length > 0 ? (
                <div className="expertise__col">
                    <p>Other:</p>
                    <ul className="expertise__list">
                        {mainExpertiseGrouped.false.sort().map((item) => (
                            <li key={item}>{item}</li>
                        ))}
                    </ul>
                </div>
            ) : null}
        </div>
    );
};

Expertise.defaultProps = {
    className: '',
};

Expertise.propTypes = {
    items: expertiseProps.isRequired,
    className: PropTypes.string,
};

export { Expertise };
