import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { StructuredText } from 'react-datocms';

import './index.scss';

import { structuredTextContent } from '../../../types';

const RteContent = forwardRef(
    ({ title, titleAs, titleClassName, as, content, className }, ref) => {
        let Component = as;
        let TitleComponent = titleAs;

        return (
            <Component ref={ref} className={clsx('rte-content', className)}>
                {title ? (
                    <TitleComponent
                        className={clsx('rte-content__title', titleClassName)}
                    >
                        {title}
                    </TitleComponent>
                ) : null}
                <StructuredText data={content} />
            </Component>
        );
    }
);
RteContent.displayName = 'RteContent';

RteContent.defaultProps = {
    title: '',
    titleAs: 'h2',
    as: 'div',
    titleClassName: '',
    className: '',
};

RteContent.propTypes = {
    title: PropTypes.string,
    titleAs: PropTypes.string,
    titleClassName: PropTypes.string,
    as: PropTypes.string,
    content: structuredTextContent.isRequired,
    className: PropTypes.string,
};

export { RteContent };
