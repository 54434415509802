import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-link';

import './index.scss';

import { datoGatsbyImageProp, backgroundColourProps } from '../../../types';

const SingleImage = ({ title, image, addMargin, className, ...props }) => {
    const {
        caption,
        halfWidth,
        setBackgroundColour,
        backgroundColour,
        shadow,
        to,
    } = props;
    const imageData = getImage(image);
    const alt = image.alt || '';

    const ConditionalWrap = ({ condition, wrap, children }) =>
        condition ? wrap(children) : children;

    const WrapImage = (children) => (
        <figure>
            <div
                className={clsx(
                    setBackgroundColour ? 'single-image__background' : null
                )}
                style={{
                    backgroundColor: setBackgroundColour
                        ? backgroundColour.hex
                        : null,
                }}
            >
                {children}
            </div>
            <figcaption className="single-image__caption">{caption}</figcaption>
        </figure>
    );

    // eslint-disable-next-line react/prop-types
    const WithLink = ({ to, children, className }) =>
        to ? (
            <Link to={to} className={className}>
                <div className="single-image__link-wrapper"></div>
                {children}
            </Link>
        ) : (
            children
        );

    return (
        <section
            className={clsx(
                'single-image',
                addMargin && !caption ? 'single-image--space' : null,
                className
            )}
        >
            <WithLink className="single-image__link" to={to}>
                {title ? (
                    <h2 className="single-image__heading">{title}</h2>
                ) : null}
                <ConditionalWrap
                    condition={caption}
                    wrap={(wrappedChildren) => WrapImage(wrappedChildren)}
                >
                    <GatsbyImage
                        class={clsx(
                            'single-image__img',
                            halfWidth ? 'single-image__img--shrink' : null,
                            shadow ? 'single-image__img--shadow' : null
                        )}
                        loading="lazy"
                        image={imageData}
                        alt={alt}
                    />
                </ConditionalWrap>
            </WithLink>
        </section>
    );
};

SingleImage.defaultProps = {
    title: '',
    caption: '',
    addMargin: false,
    className: '',
    halfWidth: false,
    setBackgroundColour: false,
    backgroundColour: {
        hex: '#FFFFFF',
    },
    shadow: false,
    to: '',
};

SingleImage.propTypes = {
    title: PropTypes.string,
    image: datoGatsbyImageProp.isRequired,
    caption: PropTypes.string,
    addMargin: PropTypes.bool,
    className: PropTypes.string,
    halfWidth: PropTypes.bool,
    shadow: PropTypes.bool,
    setBackgroundColour: PropTypes.bool,
    backgroundColour: backgroundColourProps,
    to: PropTypes.string,
};

export { SingleImage };
