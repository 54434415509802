import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { Link } from 'gatsby-link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './index.scss';

import { DescriptionList } from '../description-list';

const Tile = ({ className, ...tileContent }) => {
    const { id, tileTitle, service, year, cover, slug } = tileContent;

    let services = [];
    service.map((name) => services.push(name.serviceName));

    const tileDetails = [
        {
            id: `${id}Client`,
            title: 'Client',
            description: [tileTitle],
        },
        {
            id: `${id}Service`,
            title: 'Service',
            description: services,
        },
        {
            id: `${id}Year`,
            title: 'Year',
            description: [year],
        },
    ];

    const imageData = getImage(cover);
    const alt = cover?.alt || '';

    return (
        <article className={clsx('tile', className)}>
            <Link to={slug} aria-label={tileTitle}>
                {cover && Object.keys(cover).length > 0 ? (
                    <GatsbyImage
                        className="tile__image"
                        loading="lazy"
                        image={imageData}
                        alt={alt}
                    />
                ) : (
                    'No cover yet'
                )}
            </Link>
            <DescriptionList
                items={tileDetails}
                listClassName="tile__details"
                blockClassName="tile__details-content"
            />
        </article>
    );
};

Tile.defaultProps = {
    className: '',
};

Tile.propTypes = {
    className: PropTypes.string,
};

export { Tile };
