import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { register as registerSwiper } from 'swiper/element/bundle';

import { datoGatsbyImageProp } from '../../../types/index';

import './index.scss';
import 'swiper/css';

registerSwiper();

const Carousel = ({ id, title, images, loading, className }) => {
    const sliderRef = useRef(null);

    const getSlides = () =>
        images.map((image, index) => {
            const alt = image.alt || '';
            const imageData = getImage(image);
            return (
                <swiper-slide key={`${id}${index}`} className="carousel__slide">
                    <GatsbyImage
                        loading={loading}
                        image={imageData}
                        alt={alt}
                    />
                </swiper-slide>
            );
        });

    const swiperParams = {
        className: 'carousel__swiper',
        slidesPerView: 1.2,
        breakpoints: {
            550: {
                slidesPerView: 2.2,
            },
            767: {
                slidesPerView: 3.5,
            },
        },
        loop: true,
        autoplay: true,
        navigation: {
            nextEl: '.carousel__control--next',
            prevEl: '.carousel__control--prev',
        },
    };

    useEffect(() => {
        Object.assign(sliderRef.current, swiperParams);
        sliderRef.current.initialize();
    }, []);

    return (
        <section className={clsx('carousel', className)} aria-labelledby={id}>
            {title ? (
                <h1 id={id} className="carousel__title">
                    {title}
                </h1>
            ) : null}
            <button className="carousel__control carousel__control--prev">
                <span className="screen-reader-text">Previous slide</span>
            </button>
            <swiper-container ref={sliderRef} init="false">
                {getSlides()}
            </swiper-container>
            <button className="carousel__control carousel__control--next">
                <span className="screen-reader-text">Next slide</span>
            </button>
        </section>
    );
};

Carousel.defaultProps = {
    title: '',
    className: '',
    loading: 'lazy',
};

Carousel.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
    images: PropTypes.arrayOf(datoGatsbyImageProp).isRequired,
    loading: PropTypes.string,
};

export { Carousel };
