import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/sass/styles.scss';

const MasterLayout = ({ children }) => <>{children}</>;

export { MasterLayout };

MasterLayout.propTypes = {
    children: PropTypes.node.isRequired,
};
