import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import './index.scss';

import { listItemProps } from '../../../types';

import { PageTitle } from '../../atoms/page-title';
import { Button } from '../../atoms/button';
import { List } from '../../atoms/list';

const PagesList = ({ items, className }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={clsx('pages-list', className)}>
            <PageTitle className="pages-list__title" as="h2">
                <Button
                    className="button--inherit"
                    onClick={() => setOpen((prevCheck) => !prevCheck)}
                >
                    More work
                </Button>
            </PageTitle>
            <List
                items={items}
                className={clsx(
                    'pages-list__list',
                    open ? 'pages-list__list--show' : null
                )}
            />
        </div>
    );
};

PagesList.defaultProps = {
    className: '',
};

PagesList.propTypes = {
    className: PropTypes.string,
    items: listItemProps.isRequired,
};

export { PagesList };
