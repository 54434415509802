import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import { CGBLink } from '../../atoms/cgb-link';

import './index.scss';

const Footer = ({
    location,
    address,
    email,
    socialHandle,
    socialLink,
    phoneNumber,
    openingTimes,
    className,
    hideContent,
    privacyUrl,
}) => (
    <footer
        className={clsx(
            'footer',
            !hideContent ? 'footer--full' : 'footer--space',
            className
        )}
    >
        {!hideContent ? (
            <div className="footer__content">
                <div className="footer__item">
                    {location ? (
                        <p className="footer__text">{location}</p>
                    ) : null}
                    {address ? <p className="footer__text">{address}</p> : null}
                </div>
                <div className="footer__item">
                    {email ? (
                        <a
                            href={`mailto:${email}`}
                            className="footer__text footer__text--space"
                        >
                            {email}
                        </a>
                    ) : null}
                    {socialLink && socialHandle ? (
                        <a
                            className="footer__text footer__text--space"
                            href={socialLink}
                        >
                            {socialHandle}
                        </a>
                    ) : socialHandle ? (
                        <p className="footer__text">{socialHandle}</p>
                    ) : null}
                    {phoneNumber ? (
                        <a
                            href={`tel:${phoneNumber.replace(/\s/g, '')}`}
                            className="footer__text"
                        >
                            {phoneNumber}
                        </a>
                    ) : null}
                    {openingTimes ? (
                        <p className="footer__text">{openingTimes}</p>
                    ) : null}
                </div>
            </div>
        ) : null}
        <div className="footer__base">
            <p className="footer__copyright">
                The Contemporary Graphics Bureau ©
            </p>
            {privacyUrl ? <CGBLink to={privacyUrl}>Privacy</CGBLink> : null}
        </div>
    </footer>
);

Footer.defaultProps = {
    className: '',
    location: '',
    address: '',
    email: '',
    socialHandle: '',
    socialLink: '',
    phoneNumber: '',
    openingTimes: '',
    hideContent: false,
    privacyUrl: '',
};

Footer.propTypes = {
    className: PropTypes.string,
    location: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    socialHandle: PropTypes.string,
    socialLink: PropTypes.string,
    phoneNumber: PropTypes.string,
    openingTimes: PropTypes.string,
    privacyUrl: PropTypes.string,
    hideContent: PropTypes.bool,
};

export { Footer };
