import React from 'react';
import PropTypes from 'prop-types';
import CookieConsent from 'react-cookie-consent';

import { CGBLink } from '../../atoms/cgb-link';

import './index.scss';

const CookieBanner = ({ visible, hostname, privacyUrl }) => {
    const gaTag = process.env.GATSBY_GA_TAG;

    const onCookieOptIn = () => {
        window[`ga-disable-${gaTag}`] = false;
    };

    return (
        <CookieConsent
            visible={visible}
            disableStyles={true}
            location="bottom"
            buttonText="OK"
            cookieName="cookiesAccepted"
            containerClasses="cookie-banner"
            buttonClasses="cookie-banner__button"
            contentClasses="cookie-banner__content"
            expires={150}
            onAccept={() => {
                onCookieOptIn();
            }}
        >
            <p>
                This website uses cookies to enhance the user experience.{' '}
                <CGBLink to={privacyUrl}>Find out more about cookies.</CGBLink>
            </p>
        </CookieConsent>
    );
};

CookieBanner.defaultProps = {
    visible: 'byCookieValue',
};

CookieBanner.propTypes = {
    visible: PropTypes.string,
    privacyUrl: PropTypes.string.isRequired,
    hostname: PropTypes.string.isRequired,
};

export { CookieBanner };
