import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { renderNodeRule, StructuredText } from 'react-datocms';
import { isParagraph } from 'datocms-structured-text-utils';

import './index.scss';

import { testimonialProps } from '../../../types';

const Testimonial = ({ reviewer, review, className }) => (
    <figure className={clsx('testimonial', className)}>
        <figcaption>{reviewer}</figcaption>
        <blockquote className="testimonial__review">
            <StructuredText
                customNodeRules={[
                    renderNodeRule(isParagraph, ({ children, key }) => (
                        <React.Fragment key={key}>{children}</React.Fragment>
                    )),
                ]}
                data={review}
            />
        </blockquote>
    </figure>
);

Testimonial.defaultProps = {
    className: '',
};

Testimonial.propTypes = {
    ...testimonialProps,
    className: PropTypes.string,
};

export { Testimonial };
