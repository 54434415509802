import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './index.scss';

import { datoGatsbyImageProp } from '../../../types';

const ImageTiles = ({ ...props }) => {
    const { caption, className, tiles } = props;
    return (
        <figure
            className={clsx(
                'image-tiles',
                !caption ? 'image-tiles--space' : null,
                className
            )}
        >
            <div className="image-tiles__grid">
                {tiles.map((tile, index) => {
                    const imageData = getImage(tile);
                    const alt = tile.alt || '';
                    return (
                        <GatsbyImage
                            className="image-tiles__image"
                            key={`${tile.id}${index}`}
                            loading="lazy"
                            image={imageData}
                            objectFit="contain"
                            alt={alt}
                        />
                    );
                })}
            </div>
            <figcaption className="image-tiles__caption">{caption}</figcaption>
        </figure>
    );
};

ImageTiles.defaultProps = {
    className: '',
    caption: '',
};

ImageTiles.propTypes = {
    caption: PropTypes.string,
    className: PropTypes.string,
    tiles: PropTypes.arrayOf(datoGatsbyImageProp).isRequired,
};

export { ImageTiles };
