import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './index.scss';

const ServiceFilter = ({ className, ...props }) => {
    const {
        title,
        options,
        setFilter,
        setQueryName,
        setNestedQueryName,
        buttonId,
        contentId,
    } = props;
    const contentProps = {};

    options.sort((a, b) => a.name.localeCompare(b.name));

    const [open, setOpen] = useState(false);

    if (open) {
        contentProps['aria-labelledby'] = buttonId;
    }

    return (
        <div className={clsx('service-filter', className)}>
            <div className="service-filter__header">
                <h2 className="service-filter__title">
                    <button
                        onClick={() => setOpen((prevCheck) => !prevCheck)}
                        id={buttonId}
                        aria-expanded={open}
                        aria-controls={contentId}
                        type="button"
                        className={clsx(
                            'service-filter__button service-filter__button--header',
                            open ? 'service-filter__button--open' : null
                        )}
                    >
                        {title}
                    </button>
                </h2>
                {open ? (
                    <button
                        className="service-filter__button service-filter__button--header"
                        onClick={() => {
                            setFilter('');
                            setQueryName('allWork');
                            setNestedQueryName('featuredTiles');
                        }}
                    >
                        Reset
                    </button>
                ) : null}
            </div>
            <div
                className={clsx(
                    'service-filter__options',
                    !open ? 'service-filter__options--hidden' : null
                )}
                id={contentId}
                {...contentProps}
            >
                {options.map((option) => (
                    <button
                        key={option.id}
                        data-value={option.id}
                        onClick={(e) => {
                            setFilter(e.target.getAttribute('data-value'));
                            setQueryName('allProjects');
                            setNestedQueryName('');
                        }}
                        className="service-filter__button"
                        aria-hidden={!open}
                    >
                        {option.name}
                    </button>
                ))}
            </div>
        </div>
    );
};

ServiceFilter.defaultProps = {
    className: '',
};

ServiceFilter.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        })
    ).isRequired,
    setFilter: PropTypes.func.isRequired,
    setQueryName: PropTypes.func.isRequired,
    setNestedQueryName: PropTypes.func.isRequired,
    buttonId: PropTypes.string.isRequired,
    contentId: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export { ServiceFilter };
