import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import './index.scss';

import { List } from '../../atoms/list';

import { listItemProps } from '../../../types';

const ContactDetails = ({ content, className }) => {
    const {
        team,
        location,
        address,
        email,
        socialHandle,
        socialLink,
        phoneNumber,
        openingTimes,
    } = content;

    return (
        <div className={clsx('contact-details', className)}>
            {team.length > 0 ? (
                <List className="list--no-style" title="Team:" items={team} />
            ) : null}

            <div className="contact-details__text contact-details__address">
                {location ? <p>{location}</p> : null}
                {address ? (
                    <p>
                        {address.includes(',')
                            ? address.replace(/,/g, '')
                            : address}
                    </p>
                ) : null}
            </div>

            {email ? (
                <a
                    className="contact-details__text contact-details__link contact-details__email"
                    href={`mailto:${email}`}
                >
                    {email}
                </a>
            ) : null}

            {socialLink && socialHandle ? (
                <a
                    className="contact-details__text contact-details__link"
                    href={socialLink}
                >
                    {socialHandle}
                </a>
            ) : socialHandle ? (
                <p>{socialHandle}</p>
            ) : null}

            {phoneNumber ? (
                <a
                    className="contact-details__text contact-details__text--narrow contact-details__link"
                    href={`tel:${phoneNumber.replace(/\s/g, '')}`}
                >
                    {phoneNumber}
                </a>
            ) : null}

            {openingTimes ? (
                <p className="contact-details__text contact-details__text--narrow">
                    {openingTimes}
                </p>
            ) : null}
        </div>
    );
};

ContactDetails.defaultProps = {
    className: '',
};

ContactDetails.propTypes = {
    className: PropTypes.string,
    content: PropTypes.shape({
        team: listItemProps,
        location: PropTypes.string,
        address: PropTypes.string,
        email: PropTypes.string,
        socialHandle: PropTypes.string,
        socialLink: PropTypes.string,
        phoneNumber: PropTypes.string,
        openingTimes: PropTypes.string,
    }).isRequired,
};

export { ContactDetails };
