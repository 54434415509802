import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import './index.scss';

const PageTitle = ({ as, animate, children, className, ...attributes }) => {
    let Component = as;
    return (
        <Component className={clsx('page-title', className)} {...attributes}>
            {children}
        </Component>
    );
};

PageTitle.defaultProps = {
    as: 'h1',
    className: '',
    animate: '',
};

PageTitle.propTypes = {
    as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    animate: PropTypes.string,
};

export { PageTitle };
