import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Vimeo } from '../../atoms/vimeo';
import { Youtube } from '../../atoms/youtube';

import './index.scss';

import { datoGatsbyImageProp, backgroundColourProps } from '../../../types';

const ImageVideo = ({ ...props }) => {
    const { backgroundColour, caption, image, video, shadow } = props;
    const { __typename: type, ...rest } = video;

    const getVideoType = (type) => {
        if (type === 'DatoCmsYoutube') {
            return (
                <Youtube
                    className={clsx(
                        'image-video__video--square',
                        shadow ? 'image-video__video--shadow' : null
                    )}
                    video={rest.youtubeVideo.url}
                    loop={true}
                />
            );
        } else if (type === 'DatoCmsVimeo') {
            return (
                <Vimeo
                    className={clsx(
                        'image-video__video--square',
                        shadow ? 'image-video__video--shadow' : null
                    )}
                    video={rest.vimeoVideo.url}
                    loop={true}
                />
            );
        } else {
            return;
        }
    };
    const imageData = getImage(image);
    const alt = image.alt || '';

    return (
        <figure
            className={clsx(
                'image-video',
                !caption ? 'image-video--space' : null
            )}
        >
            <div
                className="image-video__background"
                style={{
                    backgroundColor:
                        backgroundColour && backgroundColour.hex
                            ? backgroundColour.hex
                            : '#FFF',
                }}
            >
                <div className={`image-video__wrapper`}>
                    <div className={clsx('image-video__column')}>
                        <GatsbyImage
                            className={clsx(
                                'image-video__image',
                                shadow ? 'image-video__image--shadow' : null
                            )}
                            objectFit="cover"
                            loading="lazy"
                            image={imageData}
                            quality={100}
                            alt={alt}
                        />
                    </div>
                    <div className={clsx('image-video__column')}>
                        {getVideoType(type)}
                    </div>
                </div>
            </div>
            {caption ? (
                <figcaption className="image-video__caption">
                    {caption}
                </figcaption>
            ) : null}
        </figure>
    );
};

ImageVideo.defaultProps = {
    className: '',
    backgroundColour: {
        hex: '#FFFFFF',
    },
    caption: '',
    imagesFit: 'contain',
    shadow: false,
};

ImageVideo.propTypes = {
    id: PropTypes.string.isRequired,
    image: datoGatsbyImageProp.isRequired,
    video: PropTypes.shape({
        __typename: PropTypes.string.isRequired,
        id: PropTypes.string,
        youtubeVideo: PropTypes.shape({
            url: PropTypes.string,
        }),
        vimeoVideo: PropTypes.shape({
            url: PropTypes.string,
        }),
    }).isRequired,
    caption: PropTypes.string,
    className: PropTypes.string,
    backgroundColour: backgroundColourProps,
    imagesFit: PropTypes.oneOf(['contain', 'cover']),
    shadow: PropTypes.bool,
};

export { ImageVideo };
