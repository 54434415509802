import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import './index.scss';

import { List } from '../../atoms/list';
import { DescriptionList } from '../../atoms/description-list';
import { listItemProps, descriptionListItemProps } from '../../../types';

const HonorsContainer = ({ content, className }) => {
    const { selectedClients, awards, features, talks } = content;

    return (
        <section className={clsx('honors-container', className)}>
            <List
                className="list--narrow"
                title="Selected Clients:"
                items={selectedClients}
            />
            <DescriptionList
                className="description-list--narrow"
                title="Awards:"
                items={awards}
            />
            <DescriptionList
                className="description-list--narrow"
                title="Features:"
                items={features}
            />
            <DescriptionList
                className="description-list--narrow"
                title="Talks:"
                items={talks}
            />
        </section>
    );
};

HonorsContainer.defaultProps = {
    className: '',
};

HonorsContainer.propTypes = {
    className: PropTypes.string,
    content: PropTypes.shape({
        selectedClients: listItemProps.isRequired,
        awards: descriptionListItemProps.isRequired,
        features: descriptionListItemProps.isRequired,
        talks: descriptionListItemProps.isRequired,
    }).isRequired,
};

export { HonorsContainer };
