import { useState, useEffect } from 'react';
import { useDynamicGraphQLCall } from '../../utils/dynamic-query';
import { mapToGatsbyFormat } from '../../utils/gatsby-format';

export const useFilters = ({
    queryName,
    nestedQueryName,
    query,
    data,
    itemLimit,
}) => {
    const [items, setItems] = useState(data?.nodes || []);
    const [filter, setFilter] = useState('');

    const [pagination, setPagination] = useState({
        currentPage: 0,
        totalPages: Math.ceil(data?.totalCount / itemLimit),
    });

    const currentQuery = query({
        queryName,
        nestedQueryName,
        filter,
        skip: pagination.currentPage * itemLimit,
    });

    // Make a dynamic graphQL call with the pagination or filter query and return the result
    const {
        loading,
        error,
        data: newData,
    } = useDynamicGraphQLCall({ filter, pagination, query: currentQuery });

    useEffect(() => {
        if (!newData) return;

        let newDataArray = [];
        if (nestedQueryName) {
            newDataArray = newData[queryName][nestedQueryName];
        } else {
            newDataArray = newData[queryName];
        }

        if (newDataArray.length) {
            const sortNodes = newDataArray.map(mapToGatsbyFormat);
            setItems(sortNodes);
        } else {
            setItems([]);
        }
    }, [newData]);

    // Reset pagination when the filter changes
    const updateSetFilter = (filterArg) => {
        setFilter(filterArg);
        setPagination({
            ...pagination,
            currentPage: 0,
        });
    };

    return {
        loading,
        error,
        pagination,
        filter,
        items,
        setPagination,
        setFilter: updateSetFilter,
    };
};
