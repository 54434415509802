import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import './index.scss';

const Button = ({ children, className, ...attributes }) => (
    <button className={clsx('button', className)} {...attributes}>
        {children}
    </button>
);

Button.defaultProps = {
    className: '',
};

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export { Button };
