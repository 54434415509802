import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'gatsby-link';

import './index.scss';

const YoutubeBase = (props) => {
    const {
        video,
        videoTitle,
        className,
        containerClassName,
        to,
        controls,
        loop,
        loading,
    } = props;

    const videoType = /^https?:/i.test(video) ? 'url' : 'id';
    if (videoType === 'url' && !video.includes('youtube')) {
        return <></>;
    }

    const containerRef = useRef();

    // eslint-disable-next-line react/prop-types
    const WithLink = ({ to, children, className }) =>
        to ? (
            <Link to={to} className={className}>
                {children}
            </Link>
        ) : (
            children
        );

    return (
        <div ref={containerRef} className={clsx('youtube', containerClassName)}>
            <WithLink className="youtube__link" to={to}>
                {videoTitle ? (
                    <h2 className="youtube__heading">{videoTitle}</h2>
                ) : null}
            </WithLink>
            <player-x
                src={video}
                autoplay=""
                muted=""
                playsinline=""
                loading={loading}
                class={clsx('youtube__player', className)}
                {...{
                    ...(controls && { controls: '' }),
                    ...(loop && { loop: '' }),
                }}
            ></player-x>
        </div>
    );
};

export default YoutubeBase;

YoutubeBase.propTypes = {
    video: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    videoTitle: PropTypes.string,
    bannerSubHeading: PropTypes.string,
    titleGroupClassNames: PropTypes.string,
    containerClassName: PropTypes.string,
    className: PropTypes.string,
    to: PropTypes.string,
    controls: PropTypes.bool,
    loop: PropTypes.bool,
    loading: PropTypes.string,
};

YoutubeBase.defaultProps = {
    videoTitle: '',
    bannerSubHeading: '',
    titleGroupClassNames: '',
    className: '',
    containerClassName: '',
    to: '',
    config: {},
    loading: 'lazy',
    controls: false,
    loop: false,
};
