import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import './index.scss';

const TwoCol = ({
    firstColItems,
    secondColItems,
    className,
    colClassName,
    as,
    reverse,
    ...attributes
}) => {
    let Component = as;
    return (
        <Component
            className={clsx(
                'two-col',
                className,
                reverse ? 'two-col--reverse' : null
            )}
            {...attributes}
        >
            <div className={clsx('two-col__col', colClassName)}>
                {firstColItems.map((item) => item)}
            </div>
            <div className={clsx('two-col__col', colClassName)}>
                {secondColItems.map((item) => item)}
            </div>
        </Component>
    );
};

TwoCol.defaultProps = {
    className: '',
    colClassName: '',
    as: 'section',
    reverse: false,
};

TwoCol.propTypes = {
    as: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    className: PropTypes.string,
    colClassName: PropTypes.string,
    firstColItems: PropTypes.arrayOf(PropTypes.element).isRequired,
    secondColItems: PropTypes.arrayOf(PropTypes.element).isRequired,
    reverse: PropTypes.bool,
};

export { TwoCol };
