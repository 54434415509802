import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import './index.scss';

import { RteContent } from '../../atoms/rte-content';
import { Expertise } from '../../atoms/expertise';

import { structuredTextContent, expertiseProps } from '../../../types';

const StudioIntro = ({ introContent, expertise, className }) => (
    <section className={clsx('studio-intro', className)}>
        <RteContent className="studio-intro__copy" content={introContent} />
        <Expertise className="expertise--narrow" items={expertise} />
    </section>
);

StudioIntro.defaultProps = {
    className: '',
    expertise: [],
};

StudioIntro.propTypes = {
    className: PropTypes.string,
    introContent: structuredTextContent.isRequired,
    expertise: expertiseProps.isRequired,
};

export { StudioIntro };
