import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'gatsby-link';

import LogoIcon from '../../../assets/svgs/logo.inline.svg';

import './index.scss';

const Logo = forwardRef(
    ({ to, className, svgClassName, ...attributes }, ref) => (
        <Link
            ref={ref}
            className={clsx('logo', className)}
            to={to}
            {...attributes}
        >
            <LogoIcon aria-hidden className={clsx('logo__svg', svgClassName)} />
            <span className="logo__text">
                <span className="logo__text-block logo__text-block--low">
                    The
                </span>{' '}
                <span className="logo__text-block">Contemporary</span>{' '}
                <span className="logo__text-block">Graphics Bureau</span>
            </span>
        </Link>
    )
);
Logo.displayName = 'Logo';

Logo.defaultProps = {
    to: '/',
    className: '',
    svgClassName: '',
};

Logo.propTypes = {
    to: PropTypes.string,
    className: PropTypes.string,
    svgClassName: PropTypes.string,
};

export { Logo };
