/* eslint-disable react/prop-types */
import React from 'react';
import { graphql, Script } from 'gatsby';

import { DefaultLayout } from '../layouts/default';

import { CGBLink } from '../components/atoms/cgb-link';
import { PageTitle } from '../components/atoms/page-title';
import { RteContent } from '../components/atoms/rte-content';
import { SingleImage } from '../components/atoms/single-image';
import { Vimeo } from '../components/atoms/vimeo';
import { SEO } from '../components/atoms/seo';

import { ImageGallery } from '../components/molecules/image-gallery';
import { ImageGrid } from '../components/molecules/image-grid';
import { ImageTiles } from '../components/molecules/image-tiles';
import { Carousel } from '../components/molecules/carousel';
import { ProjectIntro } from '../components/molecules/project-intro';
import { Video } from '../components/molecules/video';
import { CookieBanner } from '../components/molecules/cookie-banner';
import { ImageVideo } from '../components/molecules/image-video';

const ProjectPage = ({ data }) => {
    const {
        title,
        introductoryCopy,
        content,
        hero,
        addLogo,
        logo,
        logoPosition,
    } = data.datoCmsProject;

    const getHero = () => {
        const { __typename: type, ...props } = hero;

        switch (type) {
            case 'DatoCmsCarousel':
                return <Carousel {...props} />;
            case 'DatoCmsVimeo':
                return (
                    <Vimeo
                        video={props.vimeoVideo.url}
                        title={false}
                        loop={true}
                    />
                );
            case 'DatoCmsFullWidthImage':
                return <SingleImage {...props} />;
            default:
                return null;
        }
    };

    const getContent = (component) => {
        const { __typename: type, id, ...props } = component;

        switch (type) {
            case 'DatoCmsImageGallery':
                return <ImageGallery key={id} id={id} {...props} />;
            case 'DatoCmsSingleImage':
                return <SingleImage key={id} id={id} addMargin {...props} />;
            case 'DatoCmsImageTiled':
                return <ImageTiles key={id} id={id} {...props} />;
            case 'DatoCmsImageVideoSquared':
                return <ImageVideo key={id} id={id} {...props} />;
            case 'DatoCmsImageGrid':
                return <ImageGrid key={id} id={id} {...props} />;
            case 'DatoCmsVideo':
                return <Video key={id} id={id} fullScreen {...props} />;
            case 'DatoCmsCopy':
                return (
                    <React.Fragment key={id}>
                        <PageTitle as="h2">{props.title}</PageTitle>
                        <RteContent
                            className="rte-content--restrict"
                            content={props.text}
                        />
                    </React.Fragment>
                );
            default:
                return null;
        }
    };

    const introProps = {
        content: introductoryCopy,
        show: addLogo,
        logo,
        logoPosition,
    };

    return (
        <>
            {hero && Object.keys(hero).length > 0 ? getHero() : null}
            <PageTitle>{title}</PageTitle>
            <ProjectIntro {...introProps} />
            {content.map((component) => getContent(component))}

            <PageTitle as="h2">
                <CGBLink className="link--inherit" to="/work">
                    More work
                </CGBLink>
            </PageTitle>
        </>
    );
};

export const query = graphql`
    query ($slug: String!) {
        datoCmsProject(slug: { eq: $slug }) {
            title
            introductoryCopy {
                blocks
                links
                value
            }
            addLogo
            logo {
                gatsbyImageData
                alt
            }
            logoPosition
            content {
                __typename
                ... on DatoCmsImageGallery {
                    id
                    caption
                    alignment
                    equalWidths
                    halfWidth
                    shadow
                    backgroundColour {
                        hex
                    }
                    images {
                        id: originalId
                        alt
                        gatsbyImageData
                    }
                }
                ... on DatoCmsSingleImage {
                    id
                    caption
                    halfWidth
                    shadow
                    setBackgroundColour
                    backgroundColour {
                        hex
                    }
                    image {
                        id: originalId
                        alt
                        gatsbyImageData
                    }
                }
                ... on DatoCmsImageTiled {
                    id
                    caption
                    tiles {
                        id: originalId
                        alt
                        gatsbyImageData
                    }
                }
                ... on DatoCmsImageGrid {
                    id
                    caption
                    imagesFit
                    shadow
                    backgroundColour {
                        hex
                    }
                    columns {
                        images {
                            id: originalId
                            alt
                            gatsbyImageData
                        }
                    }
                }
                ... on DatoCmsVideo {
                    id
                    caption
                    video {
                        __typename
                        ... on DatoCmsVimeo {
                            id
                            vimeoVideo {
                                url
                            }
                        }
                        ... on DatoCmsYoutube {
                            id
                            youtubeVideo {
                                url
                            }
                        }
                    }
                }
                ... on DatoCmsImageVideoSquared {
                    id
                    caption
                    shadow
                    backgroundColour {
                        hex
                    }
                    image {
                        id: originalId
                        alt
                        gatsbyImageData
                    }
                    video {
                        __typename
                        ... on DatoCmsVimeo {
                            id
                            vimeoVideo {
                                url
                            }
                        }
                        ... on DatoCmsYoutube {
                            id
                            youtubeVideo {
                                url
                            }
                        }
                    }
                }
                ... on DatoCmsCopy {
                    id
                    title
                    text {
                        blocks
                        links
                        value
                    }
                }
            }
            hero {
                __typename
                ... on DatoCmsCarousel {
                    id
                    images {
                        alt
                        gatsbyImageData
                        originalId
                    }
                }
                ... on DatoCmsVimeo {
                    id
                    vimeoVideo {
                        url
                    }
                }
                ... on DatoCmsFullWidthImage {
                    id
                    image {
                        originalId
                        alt
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            imgixParams: {
                                ar: "48:19"
                                fit: "crop"
                                crop: "focalpoint"
                            }
                        )
                    }
                }
            }
        }
    }
`;

const WrappedProject = ({ data, location }) => (
    <DefaultLayout>
        <ProjectPage data={data} location={location} />
        <CookieBanner hostname={location.hostname} privacyUrl="/privacy" />
    </DefaultLayout>
);

export default WrappedProject;

export const Head = ({ data, location }) => {
    const { title } = data.datoCmsProject;

    return (
        <>
            <SEO title={title} pathname={location.pathname} />
            <Script
                type="module"
                src="https://cdn.jsdelivr.net/npm/playerx/+esm"
                strategy="idle"
            ></Script>
        </>
    );
};
