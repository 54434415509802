/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { resetCookieConsentValue } from 'react-cookie-consent';

import { DefaultLayout } from '../layouts/default';

import { PageTitle } from '../components/atoms/page-title';
import { SEO } from '../components/atoms/seo';
import { RteContent } from '../components/atoms/rte-content';

import { CookieBanner } from '../components/molecules/cookie-banner';

import { useCookie } from '../hooks/cookie';
import { isLocalhost } from '../utils/is-localhost';
import { clearCookies } from '../utils/clear-cookies';

const Privacy = ({ data, location }) => {
    const gaTag = process.env.GATSBY_GA_TAG;
    const contentRef = useRef();
    const { title, content } = data.datoCmsPrivacy;
    const [bannerVisible, setBannerVisible] = useState('byCookieValue');

    const handleCookieClear = (hostname) => {
        if (useCookie('cookiesAccepted')) {
            clearCookies(hostname);
            resetCookieConsentValue('cookiesAccepted');
            setBannerVisible('visible');

            if (isLocalhost(hostname) === false) {
                window[`ga-disable-${gaTag}`] = true;
            }
        }
    };

    useEffect(() => {
        const clearCookieLink = contentRef.current.querySelector(
            'a[href="#clear-cookies"]'
        );
        if (clearCookieLink) {
            clearCookieLink.addEventListener('click', () => {
                handleCookieClear(location.hostname);
            });
        }
    }, [contentRef]);

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <RteContent
                ref={contentRef}
                className="rte-content--restrict rte-content--print"
                content={content}
            />
            <CookieBanner
                visible={bannerVisible}
                hostname={location.hostname}
                privacyUrl="/privacy"
            />
        </>
    );
};

export const query = graphql`
    query {
        datoCmsPrivacy {
            id
            title
            content {
                blocks
                links
                value
            }
        }
    }
`;

const WrappedPrivacy = ({ data, location }) => (
    <DefaultLayout>
        <Privacy location={location} data={data} />
    </DefaultLayout>
);

export default WrappedPrivacy;

export const Head = ({ data, location }) => {
    const { title } = data.datoCmsPrivacy;

    return <SEO title={title} pathname={location.pathname} />;
};
